@use 'src/Theme';
@use 'src/Common';

$requestBtnRaduis: 30px;
@mixin requestButton($color) {
  border-radius: $requestBtnRaduis;
  background-color: #fff;
  border: 1px solid $color;
  color: $color;
}
.wrapper {
  .header {
    @include Common.pageHeader;
    .pageTitle {
      @include Common.pageTitle;
    }
  }
  .subTitle {
    margin-top: 1rem;
    color: #626262;
  }
  .reqBtn {
    @include requestButton(#027be3);
  }
  .cancelBtn {
    @include requestButton(#ea5550);
  }
  .card {
    margin-bottom: 0.5rem;
    border-color: Theme.$mainColor;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    .body {
      padding-top: 0px;
      padding-bottom: 0.2rem;
    }
    .title {
      font-size: 1rem;
      padding-top: 0.5rem;
      font-weight: 600;
      color: #666;
    }
    .itemLabel {
      text-align: center;
      margin-right: 0px;
      padding-left: 5px;
      padding-right: 0px;
      color: #9d9c9c;
      font-size: 0.8rem;
    }
  }
}
