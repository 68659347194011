@use 'src/Theme';

.guidanceMaterial {
  .breadcrumbs {
    width:100%;
    .breadcrumb ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0.1rem;
      margin: 0 0 0.5rem 0;
    }
    .breadcrumb ul {
      list-style: none;
      padding-left: 0;
    }
    .breadcrumb {
      line-height: 20px;
      overflow-y: hidden;
      .breadcrumbLink {
        color: #409eff;
        text-decoration: none;
      }
    }
  }
  .pathIconWrapper {
    margin-right: 0.5rem;
  }
  .fileIcon {
    color: Theme.$mainColor;
  }
  .completed {
    color: #409eff;
    margin-right: 0.25rem;
  }
}
.movieWrapper {
  padding: 0px;
  height: 100dvh;
  .controls {
    height: 60px;
    padding-top: 14px;
    background: rgb(58 56 62 / 30%);
    background: linear-gradient(to bottom, rgba(58, 56, 62, 1%), rgba(58, 56, 62, 100%));
    position: relative;
    top: -66px;
    left: 0px;
    z-index: 9999;
    .controlsTop {
      padding: 0 16px;
    }
    .controlsBottom {
      padding: 0 10px;
    }
    .controlBtn {
      color: #fff;
      background-color: unset;
      outline: none;
    }
    .playTime {
      color: #fff;
      font-size: 0.75rem;
    }
    .movieTitle {
      color: #fff;
      font-size: 0.85rem;
    }
  }
  .controlShow {
    display: block;
  }
  .controlHide {
    display: none;
  }
  .noMarginRow {
    margin-left: 0px;
    margin-right: 0px;
  }
  .warningOrientation {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(58, 56, 62, 50%);
    .warningOrientationMessage {
      color: #fff;
      position: fixed;
      text-align: center;
      width: 100%;
      top: 45%;
      font-size: 0.9rem;
    }
  }
}
:global {
  .portal-video {
    width: 100dvw;
    height: 100dvh;
  }
  .video-player {
    height: 100%;
    overflow: hidden;
    background: rgb(58, 56, 62);
    &.show-video {
      display: block;
    }
    &.hide-video {
      display: none;
    }
  }
  .portal-movie-slider {
    height: 16px;
  }
  .portal-movie-slider-thumb {
    top: -2px;
    width: 8px;
    background-color: #fff;
    height: 8px;
    text-align: center;
    border-radius: 50%;
    cursor: grab;
    outline: none !important;
  }
  .portal-movie-slider-track {
    height: 3px;
    border-radius: 999px;
  }
  .portal-movie-slider-track-0 {
    background-color: #409eff;
  }
  .portal-movie-slider-track-1 {
    background-color: #e4e7ed;
  }
  .portal-volume-slider {
    height: 16px;
  }
  .portal-volume-slider-thumb {
    top: -2px;
    width: 8px;
    background-color: #fff;
    height: 8px;
    text-align: center;
    border-radius: 50%;
    cursor: grab;
    outline: none !important;
  }
  .portal-volume-slider-track {
    height: 3px;
    border-radius: 999px;
  }
  .portal-volume-slider-track-0 {
    background-color: #fff;
  }
  .portal-volume-slider-track-1 {
    background-color: #e4e7ed;
    opacity: 0.2;
  }
}
