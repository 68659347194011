@use 'src/Variable';
@use 'src/Common';
@use 'src/Theme';

.profileButton {
  margin-left: 5px;
}
.selection {
  width: 140px;
}
.zip1 {
  width: 40px;
}
.zip2 {
  width: 50px;
}

.wrapper {
  .header {
    @include Common.pageHeader;
    .pageTitle {
      @include Common.pageTitle;
    }
  }
  .editBtn {
    color: Theme.$mainColor;
    background-color: Theme.$subColor;
  }
  .iconWrapper {
    display: inline-block;
    width: 30px;
    text-align: center;
  }
}
.editWrapper {
  .header {
    @include Common.pageHeader;
    .pageTitle {
      @include Common.pageTitle;
    }
  }
  .zip1Col {
    padding-right: 0px;
  }
  .zipSeparateCol {
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
    padding-top: 0.3rem;
  }
  .zip2Col {
    padding-left: 0px;
  }
  .subItemLabel {
    font-size: 0.875rem;
    color: Variable.$dspItelLabelFontColor;
  }
  .footer {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}

.image {
  width: 90%;
}
