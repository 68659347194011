@use 'src/Theme';
@use 'src/Variable';
@use 'src/Common';

@mixin reportList {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
}

.spaceB {
  margin-bottom: 0.5rem;
}
.slashSeparate {
  text-align: center;
  margin-top: 0.2rem;
}
.addNewTextButton {
  background-color: Theme.$mainColor;
  color: #fcf8f8;
  font-size: 14px;
  border-radius: 30px;
  margin-bottom: 0.5rem;
}
.xmarkButton {
  background-color: #fff;
  color: #e7402a;
  font-size: 14px;
  border-radius: 30px;
  border: 1px solid;
  border-color: #e7402a;
}
.listWrapper {
  .paymented {
    background-color: Theme.$paymentedColor;
    @include reportList;
  }
  .approved {
    background-color: Theme.$approvedColor;
    @include reportList;
  }
  .turnIn {
    background-color: Theme.$turnInColor;
    @include reportList;
  }
  .notTurnIn {
    background-color: Theme.$notTurnInColor;
    @include reportList;
  }
  .title {
    font-weight: 800;
  }
  .status {
    float: left;
    width: 80px;
    color: #fff;
  }
  .date {
    float: left;
    text-align: right;
    width: calc(100% - 80px);
    color: #fff;
    span {
      color: #7a7a7a;
      font-size: 0.8rem;
      margin-right: 8px;
    }
  }
}
.editFormWrapper {
  .header {
    margin-top: 1rem;
    background-color: Theme.$subColor;
    border: none;
    .title {
      text-align: center;
      color: #7a7a7a;
      font-size: 0.75rem;
      margin-top: 0.7rem;
    }
  }
  .studentPanel {
    .studentName {
      color: #626262;
      text-align: center;
      font-size: 1.3em;
      font-weight: bold;
    }
  }
  .pagerBtn {
    background-color: Theme.$subColor;
    width: 45px;
    height: 45px;
    color: #7f7f7f;
  }
  .card {
    padding: 0.5rem;
    margin-bottom: 10px;
    border-color: Theme.$mainColor;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .scoreTable {
    width: 100%;
    tbody {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
      td {
        color: rgb(122, 122, 122);
        text-align: right;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.1rem;
      }
      .subject {
        width: 70%;
        text-align: center;
        background-color: Theme.$subColor;
      }
    }
  }
  .gradeTable {
    width: 100%;
    tbody {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
      td {
        color: rgb(122, 122, 122);
        text-align: right;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.1rem;
      }
      .subject {
        width: 70%;
        text-align: center;
        background-color: Theme.$subColor;
      }
    }
  }
  .imageLink {
    a {
      @include Common.commonLink;
    }
  }
  .closingTable {
    width: 100%;
    thead {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
    }
    th {
      width: 30%;
      color: rgb(122, 122, 122);
      text-align: center;
      background-color: Theme.$subColor;
      border-left: 1px solid;
      border-top: 1px solid;
      padding: 0.2rem;
      margin-top: 1rem;
    }
    tbody {
      tr {
        :last-child {
          border-right: 1px solid;
        }
      }
      td {
        color: rgb(122, 122, 122);
        text-align: right;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.2rem;
      }
    }
  }
  .totalAmount {
    text-align: right;
  }

  .tabIconHoukoku {
    color: Theme.$mainColor;
    font-size: 1.3rem;
    margin-left: 0.5rem;
    svg {
      transform: rotate(30deg);
    }
  }
  .tabIconTest {
    color: Theme.$commonRightColor;
    font-size: 1.3rem;
  }
  .tabIconSeiseki {
    color: Theme.$commonLeftColor;
    font-size: 1.3rem;
  }
  .tabIconShime {
    color: Theme.$commonMainColor;
    font-size: 1.3rem;
  }
  .tabTitle {
    font-size: 0.5rem;
    color: #495057;
  }
}
:global {
  .report-tabs {
    margin-top: 0.5rem;
    border-color: Theme.$mainColor !important;
    .nav-link {
      margin-bottom: -2px !important;
      &.active {
        border-color: Theme.$mainColor Theme.$mainColor #fff !important;
      }
    }
  }
}
