@use 'src/Theme.scss';

.dataTableWrapper {
  .summary {
    margin-bottom: 8px;
  }
  .personalPanel {
    padding: 0.5rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-color: Theme.$mainColor;
    margin-bottom: 1rem;
    .subTitle {
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      color: #525151;
    }
    .iconWrapper {
      padding-right: 1rem;
    }
    .reportBtn {
      background-color: Theme.$mainColor;
      color: #fcf8f8;
      font-size: 14px;
      border-radius: 30px;
      margin-bottom: 0.5rem;
    }
    .infoBtnWrapper {
      margin-left: 1rem;
      .infoBtn {
        background-color: Theme.$mainColor;
        width: 30px;
        height: 30px;
        color: #fcf8f8;
        font-size: 14px;
        border-radius: 100%;
      }
    }
    .next {
      font-size: 0.7rem;
      color: #525151;
    }
  }
}
$scoreTableBorderColor: #999;
.karteWrapper {
  .scoreTable {
    thead {
      tr {
        :last-child {
          border-right: 1px solid $scoreTableBorderColor;
        }
      }
    }
    th {
      width: 2rem;
      text-align: center;
      color: #fff;
      background-color: #a8d3ff;
      border-left: 1px solid $scoreTableBorderColor;
      border-top: 1px solid $scoreTableBorderColor;
      padding: 0.2rem;
    }
    tbody {
      tr {
        :last-child {
          border-right: 1px solid $scoreTableBorderColor;
        }
      }
      td {
        text-align: right;
        border-left: 1px solid $scoreTableBorderColor;
        border-top: 1px solid $scoreTableBorderColor;
        border-bottom: 1px solid $scoreTableBorderColor;
        padding: 0.2rem;
      }
    }
  }
  .iconWrapper {
    margin-right: 0.5rem;
    color: #817f7f;
  }
  .weekend {
    margin-right: 0.5rem;
  }
  .schedule {
    margin-right: 0.5rem;
    color: #817f7f;
    font-size: 0.8rem;
  }
  .footer {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
